import { combineReducers } from "redux";
import callStatusReducer from "./callStatusReducer";
import streamsReducer from "./streamsReducer";
import loginStateReducer from "./loginStateReducer";

const rootReducer = combineReducers({
  callStatus: callStatusReducer,
  streams: streamsReducer,
  loginState: loginStateReducer,
});

export default rootReducer;

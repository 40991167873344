import React from "react";
import { useEffect, useState } from "react";
import "./Login.css";
import axios from "axios";
import config from "../config";
import { useDispatch } from "react-redux";
import { setAuthData } from "../redux-elements/actions/updateLoginStatus";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleLogin = async () => {
    try {
      const response = await axios.post(
        `${config.cofferApiServer}/professional/login`,
        {
          email,
          password,
        }
      );

      // Handle the response, e.g., store user token in state/context
      console.log("Login successful:", response.data);
      if (response.data.error === false) {
        dispatch(setAuthData(response.data));
        const myJid = response.data.data.uid + "_" + response.data.data.user_id;
        const axResponse = await axios.post(
          `${config.chatApiServer}/api/v1/clientpro-link`,
          {
            token: response.data.token,
            toJid: myJid,
            fullName: response.data.data.name,
            proId: myJid,
          }
        );
        navigate(axResponse.data);
      } else {
        alert("Invalid Credentials!");
      }
    } catch (error) {
      // Handle errors, e.g., display an error message
      console.error("Login failed:", error.message);
    }
  };
  return (
    <div className="modern-login-container">
      <div className="modern-login-box">
        <h2>Login</h2>
        <form>
          <div className="form-group">
            <label htmlFor="username">Username</label>
            <input
              type="email"
              id="username"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label htmlFor="password">Password</label>
            <input
              type="password"
              id="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
      </div>
    </div>
  );
};

export default Login;

// CustomButton.js
import React from "react";
import PropTypes from "prop-types";
import "./customButton.css"; // Import your custom styles

const CustomButton = ({ variant, color, onClick, children, className }) =>
  React.createElement(
    "button",
    {
      className: `custom-button ${variant} ${color} ${className}`,
      onClick,
    },
    children
  );

CustomButton.propTypes = {
  variant: PropTypes.string,
  color: PropTypes.string,
  onClick: PropTypes.func,
  children: PropTypes.node,
  className: PropTypes.string,
};

export default CustomButton;

import { useDispatch, useSelector } from "react-redux";
import {
  updateCallStatus,
  clearCallStatus,
} from "../redux-elements/actions/updateCallStatus";
import { useNavigate } from "react-router-dom";
import addStream from "../redux-elements/actions/addStream";

const HangupButton = ({
  largeFeedEl,
  smallFeedEl,
  redirectHome,
  disConnectCall,
}) => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  const navigate = useNavigate();

  const hangupCall = () => {
    disConnectCall();
    dispatch(updateCallStatus("current", "complete"));
    dispatch(clearCallStatus());
    // console.log(streams);
    const localStream = streams.localStream;
    console.log(localStream);
    localStream.stream.getTracks().forEach((track) => track.stop());
    dispatch(addStream("localStream", localStream));

    const remoteStream = streams.remote1;
    console.log(remoteStream);
    remoteStream.stream.getTracks().forEach((track) => track.stop());

    //user has clicked hang up
    for (const s in streams) {
      //loop through all streams, and if there is a pc, close it
      //remove listeners
      //set it to null
      if (streams[s].peerConnection) {
        //console.log(streams[s].peerConnection);
        streams[s].peerConnection.close();
        streams[s].peerConnection.onicecandidate = null;
        streams[s].peerConnection.ontrack = null;
        streams[s].peerConnection = null;
      }

      // const tracks = streams[s].getTracks();
      // tracks.forEach((track) => track.stop());
      // setStream(null);
    }
    //set both video tags to empty
    smallFeedEl.current.srcObject = null;
    largeFeedEl.current.srcObject = null;
    if (redirectHome) {
      navigate(-1);
    } else {
      window.close();
    }
  };

  if (callStatus.current === "complete") {
    return <></>;
  }

  return (
    <button onClick={hangupCall} className="btn btn-danger hang-up">
      Hang Up
    </button>
  );
};

export default HangupButton;

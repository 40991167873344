import peerConfiguration from "./stunServers";
import SignalingChannel from "webrtc-adapter";

const createPeerConnection = (addIce) => {
  return new Promise(async (resolve, reject) => {
    try {
      // const signaler = new SignalingChannel();
      const peerConnection = new RTCPeerConnection(peerConfiguration);
      //rtcpeerconnection  is the connection to the peer
      // we may need more than one this time
      //we pass it to config object, which is just stun servers
      //it will get us ice candidates
      const remoteStream = new MediaStream();
      peerConnection.addEventListener("signalingstatechange", (e) => {
        console.log("signaling state change");
        console.log(e);
      });

      peerConnection.addEventListener("icecandidate", (e) => {
        console.log("found ICE candidate...");
        if (e.candidate) {
          addIce(e.candidate);
        }
      });

      peerConnection.addEventListener("track", (e) => {
        console.log("got a track from remote...");
        console.log(e.streams[0].getTracks());
        e.streams[0].getTracks().forEach((track) => {
          remoteStream.addTrack(track, remoteStream);
          console.log("fingers crossed...");
        });
      });

      resolve({
        peerConnection,
        remoteStream,
      });
    } catch (error) {
      console.log("error in peerConnection...");
      console.log(error);
    }
  });
};

export default createPeerConnection;

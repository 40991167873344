import { updateCallStatus } from "../redux-elements/actions/updateCallStatus";

const proDashboardSocketListeners = (socket, setApptInfo, dispatch) => {
  socket.on("testData", (apptData) => {
    console.log(" test Data called ");
    console.log(apptData);
  });

  socket.on("apptData", (apptData) => {
    console.log("------><-------");
    console.log("ApptData ->");
    console.log(apptData);
    setApptInfo(apptData);
  });

  socket.on("newOfferWaiting", (offerData) => {
    //dispatch the offer to redux so that it is available for later
    console.log(offerData);
    dispatch(updateCallStatus("offer", offerData.offer));
    dispatch(updateCallStatus("myRole", "answerer"));
  });
};

const proVideoSocketListeners = (socket, addIceCandidateToPc) => {
  socket.on("iceToClient", (iceC) => {
    console.log("pro iceC");
    addIceCandidateToPc(iceC);
  });
};

// eslint-disable-next-line import/no-anonymous-default-export
export default { proDashboardSocketListeners, proVideoSocketListeners }; //, proVideoSocketListeners };

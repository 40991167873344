import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import startLocalVideoStream from "./startLocalVideoStream";
import { updateCallStatus } from "../../redux-elements/actions/updateCallStatus";
import getDevices from "../../webRTCutilities/getDevices";
import addStream from "../../redux-elements/actions/addStream";
import ActionButtonCaretDropDown from "../ActionButtonCaretDropdown";
import "webrtc-adapter";

const VideoButton = ({ smallFeedEl }) => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  const [pendingUpdate, setPendingUpdate] = useState(false);
  const [caretOpen, setCaretOpen] = useState(false);
  const [videoDeviceList, setVideoDeviceList] = useState([]);

  useEffect(() => {
    const getDevicesAsync = async () => {
      if (caretOpen) {
        //we need to check for video Devices
        const devices = await getDevices();
        console.log(devices.videoDevices);
        setVideoDeviceList(devices.videoDevices);
      }
    };
    getDevicesAsync();
  }, [caretOpen]);

  const changeVideoDevice = (e) => {
    //The user chose to change the desired video device
    //1. we need to get the device
    const deviceId = e.target.value;
    //2. we need to get the getUserMedia (permission)
    const newConstraints = {
      audio:
        callStatus.audioDevice === "default"
          ? true
          : { deviceId: { exact: callStatus.audioDevice } },
      video: { deviceId: { exact: deviceId } },
    };
    // console.log(newConstraints);
    const stream = navigator.mediaDevices.getUserMedia(newConstraints);
    //3. Update redux with that videoDevice and  that video is enabled
    dispatch(updateCallStatus("videoDevice", deviceId));
    dispatch(updateCallStatus("video", "enabled"));
    //4. update the smallFeedEl
    smallFeedEl.current.srcObject = stream;
    //5. we need to update the localStream in stream
    dispatch(addStream("localStream", stream));
    //6. add tracks
    const [videoTrack] = stream.getVideoTracks();
    //come back to this later
    //if we stop the old tracks, and add the new Tracks, that will mean
    //... renogotation
    for (const s in streams) {
      if (s !== "localStream") {
        //getSenders will grab all the RTCRtpSenders that the PC has
        const senders = streams[s].peerConnection.getSenders();
        const sender = senders.find((s) => {
          if (s.track) {
            return s.track.kind === videoTrack.kind;
          } else {
            return false;
          }
        });
        sender.replaceTrack(videoTrack);
      }
    }
  };

  const startStopVideo = () => {
    // console.log("start stop video button  clicked...");
    //first, check video is enabled, if so disabled
    if (callStatus.video === "enabled") {
      //update redux callStatus
      dispatch(updateCallStatus("video", "disabled"));
      const tracks = streams.localStream.stream.getVideoTracks();
      tracks.forEach((t) => (t.enabled = false));
    } else if (callStatus.video === "disabled") {
      //second, check if video is disabled, if so enabled
      //update redux callStatus
      dispatch(updateCallStatus("video", "enabled"));
      const tracks = streams.localStream.stream.getVideoTracks();
      tracks.forEach((t) => (t.enabled = true));
    } else if (callStatus.haveMedia) {
      //thirdly, check to see if we have media, if so start the stream
      //we have the media show the tracks
      smallFeedEl.current.srcObject = streams.localStream.stream;
      //add tracks to peerConnection
      startLocalVideoStream(streams, dispatch);
    } else {
      setPendingUpdate(true);
    }
    //lastly, it is possible we dont have media, wait for the media and start the stream
  };

  useEffect(() => {
    //this useEffect will run if pendingUpdate changes to true
    if (pendingUpdate && callStatus.haveMedia) {
      console.log("Pending update succeeded..");
      setPendingUpdate(false);
      smallFeedEl.current.srcObject = streams.localStream.stream;
      startLocalVideoStream(streams, dispatch);
    }
  }, [pendingUpdate, callStatus.haveMedia]);
  return (
    <div className="button-wrapper video-button d-inline-block">
      <i
        className="fa fa-caret-up choose-video"
        onClick={() => {
          setCaretOpen(!caretOpen);
        }}
      ></i>
      <div className="button camera" onClick={startStopVideo}>
        <i className="fa fa-video"></i>
        <div className="btn-text">
          {callStatus.video === "enabled" ? "Stop" : "Start"} Video
        </div>
      </div>
      {caretOpen ? (
        <ActionButtonCaretDropDown
          defaultValue={callStatus.videoDevice}
          changeHandler={changeVideoDevice}
          deviceList={videoDeviceList}
          type="video"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default VideoButton;

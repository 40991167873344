const initialState = {
  authData: null,
};

// eslint-disable-next-line import/no-anonymous-default-export
export default (state = initialState, action) => {
  switch (action.type) {
    case "SET_AUTH_DATA":
      return {
        ...state,
        authData: action.payload,
      };
    default:
      return state;
  }
};

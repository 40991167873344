import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import "./App.css";
import MainVideoPage from "./videoComponents/MainVideoPage";
import ProDashboard from "./siteComponents/ProDashboard";
import ProMainVideoPage from "./videoComponents/ProMainVideoPage";
import Login from "./siteComponents/Login";
import "webrtc-adapter";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" Component={Login} />
        <Route path="join-video" Component={MainVideoPage} />
        <Route path="dashboard" Component={ProDashboard} />
        <Route path="join-video-pro" Component={ProMainVideoPage} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

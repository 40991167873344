import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import addStream from "../../redux-elements/actions/addStream";
import {
  updateCallStatus,
  clearCallStatus,
} from "../../redux-elements/actions/updateCallStatus";
import getDevices from "../../webRTCutilities/getDevices";
import socket from "../../webRTCutilities/socketConnection";
import ActionButtonCaretDropDown from "../ActionButtonCaretDropdown";
import startAudioStream from "./startAudioStream";

const AudioButton = ({ smallFeedEl }) => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const [caretOpen, setCaretOpen] = useState(false);
  const [audioDeviceList, setAudioDeviceList] = useState([]);
  const streams = useSelector((state) => state.streams);

  useEffect(() => {
    const getDevicesAsync = async () => {
      if (caretOpen) {
        //we need to check for video Devices
        const devices = await getDevices();
        console.log(devices.videoDevices);
        setAudioDeviceList(
          devices.audioOutputDevices.concat(devices.audioInputDevices)
        );
      }
    };
    getDevicesAsync();
  }, [caretOpen]);

  let micText;
  if (callStatus.audio === "off") {
    micText = "Join Audio";
  } else if (callStatus.audio === "enabled") {
    micText = "Mute";
  } else {
    micText = "Unmute";
  }

  const changeAudioDevice = async (e) => {
    //The user chose to change the desired input or output audio device
    //1. we need to get the device
    const deviceId = e.target.value.slice(5); //take everything after 5th character Input or Output is removed
    const audioType = e.target.value.slice(0, 5);
    if (audioType === "output") {
      //4. (sort of out of order ) update the smallFeedEl
      //we are now done, we dont care about the output for amy other reason
      smallFeedEl.current.setSinkId(deviceId);
    } else if (audioType === "input") {
      //2. we need to get the getUserMedia (permission)
      const newConstraints = {
        audio: { deviceId: deviceId },
        video:
          callStatus.videoDevice === "default"
            ? true
            : { deviceId: { exact: callStatus.videoDevice } },
      };

      console.log(newConstraints);
      const stream = await navigator.mediaDevices.getUserMedia(newConstraints);
      //3. Update redux with that videoDevice and  that video is enabled
      dispatch(updateCallStatus("audioDevice", deviceId));
      dispatch(updateCallStatus("audio", "enabled"));
      //5. we need to update the localStream in stream
      dispatch(addStream("localStream", stream));
      //6. add tracks
      const [audioTrack] = stream.getAudioTracks();
      for (const s in streams) {
        if (s !== "localStream") {
          const senders = streams[s].peerConnection.getSenders();
          const sender = senders.find((s) => {
            if (s.track) {
              //if this track matches the videoTrack kind, return it
              return s.track.kind === audioTrack.kind;
            } else {
              return false;
            }
          });
          //sender is RTCRtpSender, so it can replace the track
          sender.replaceTrack(audioTrack);
        }
      }
    }
  };

  const startStopAudio = (e) => {
    // console.log("start and stop...");
    if (callStatus.audio === "enabled") {
      //update redux callStatus
      dispatch(updateCallStatus("audio", "disabled"));
      const tracks = streams.localStream.stream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = false));
    } else if (callStatus.audio === "disabled") {
      //second, check if audio is disabled, if so enabled
      //update redux callStatus
      dispatch(updateCallStatus("audio", "enabled"));
      const tracks = streams.localStream.stream.getAudioTracks();
      tracks.forEach((t) => (t.enabled = true));
    } else {
      //if audio is off what to do
      changeAudioDevice({ target: { value: "inputdefault" } });
      startAudioStream(streams);
    }
  };

  return (
    <div className="button-wrapper d-inline-block">
      <i
        className="fa fa-caret-up choose-audio"
        onClick={() => setCaretOpen(!caretOpen)}
      ></i>
      <div className="button mic" onClick={startStopAudio}>
        <i className="fa fa-microphone"></i>
        <div className="btn-text">{micText}</div>
      </div>
      {caretOpen ? (
        <ActionButtonCaretDropDown
          defaultValue={callStatus.audioDevice}
          changeHandler={changeAudioDevice}
          deviceList={audioDeviceList}
          type="audio"
        />
      ) : (
        <></>
      )}
    </div>
  );
};

export default AudioButton;

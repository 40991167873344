// let peerConfiguration = new RTCPeerConnection({
//   iceServers: [
//     {
//       urls: "stun:stun.relay.metered.ca:80",
//     },
//     {
//       urls: "turn:standard.relay.metered.ca:80",
//       username: "0e22e5dfdf4d1d893b6f2a4f",
//       credential: "MJoLU7bfFhcdKQNP",
//     },
//     {
//       urls: "turn:standard.relay.metered.ca:80?transport=tcp",
//       username: "0e22e5dfdf4d1d893b6f2a4f",
//       credential: "MJoLU7bfFhcdKQNP",
//     },
//     {
//       urls: "turn:standard.relay.metered.ca:443",
//       username: "0e22e5dfdf4d1d893b6f2a4f",
//       credential: "MJoLU7bfFhcdKQNP",
//     },
//     {
//       urls: "turns:standard.relay.metered.ca:443?transport=tcp",
//       username: "0e22e5dfdf4d1d893b6f2a4f",
//       credential: "MJoLU7bfFhcdKQNP",
//     },
//   ],
// });

// let peerConfiguration = {
//   iceServers: [
//     {
//       urls: [
//         "stun:stun.l.google.com:19302",
//         "stun:stun1.l.google.com:19302",
//         "stun:stun3.l.google.com:19302",
//         "stun:stun4.l.google.com:19302",
//         // "stun:devchat.vitacape.com:3478",
//         // "stun:devchat.vitacape.com:5349",
//       ],
//     },
//     {
//       urls: "turn:relay1.expressturn.com:3478",
//       username: "efFI87YHITNA07SHNE",
//       credential: "Oq3NkqRnGFMGbJy7",
//     },
//   ],
// };

// let peerConfiguration = {
//   iceServers: [
//     {
//       urls: [
//         "stun:devchat.vitacape.com:3478",
//         "stun:devchat.vitacape.com:5349",
//       ],
//     },
//     {
//       urls: "turn:relay1.expressturn.com:3478",
//       username: "efFI87YHITNA07SHNE",
//       credential: "Oq3NkqRnGFMGbJy7",
//     },
//   ],
// };

let peerConfiguration = {
  iceServers: [
    {
      urls: ["stun:stun.l.google.com:19302", "stun:stun1.l.google.com:19302"],
    },
  ],
};

// let peerConfiguration = {
//   iceServers: [
//     {
//       urls: "turn:relay1.expressturn.com:3478",
//       username: "efFI87YHITNA07SHNE",
//       credential: "Oq3NkqRnGFMGbJy7",
//     },
//   ],
// };

export default peerConfiguration;

import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams } from "react-router-dom";
import ActionButtons from "./ActionButtons";
import CallInfo from "./Callinfo";
import ChatWindow from "./ChatWindow";
import "./VideoComponents.css";
import addStream from "../redux-elements/actions/addStream";
import { useDispatch, useSelector } from "react-redux";
import createPeerConnection from "../webRTCutilities/createPeerConnection";
import socketConnection from "../webRTCutilities/socketConnection";
import {
  updateCallStatus,
  clearCallStatus,
} from "../redux-elements/actions/updateCallStatus";
import clientSocketListeners from "../webRTCutilities/clientSocketListeners";
import config from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const MainVideoPage = () => {
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  //get query string finder hook
  const [searchParams, setSeacrchParams] = useSearchParams();
  const [apptInfo, setApptInfo] = useState({});

  //grab the token var out of the querystring
  const smallFeedEl = useRef(null); //this is a React ref to a dom element, so we can interact with it the React way
  const largeFeedEl = useRef(null);
  const uuidRef = useRef(null);
  const streamsRef = useRef(null);
  const [showCallInfo, setShowCallInfo] = useState(true);

  useEffect(() => {
    //fetch the user Media
    const fetchMedia = async () => {
      const constraints = {
        video: true, //must have one cnstraint, just dont show it yet
        audio: true,
      };
      try {
        let makingOffer = false;
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        dispatch(updateCallStatus("haveMedia", true)); // update the callstatus reducer that we have a media
        //dispatch will send this function to the redux dispatcher so all the reducers are notified
        //we send 2 args, the who and the stream
        dispatch(addStream("localStream", stream));
        const { peerConnection, remoteStream } = await createPeerConnection(
          addIce
        );

        //#SATHNEW perfect negotiation pattern
        peerConnection.onnegotiationneeded = async () => {
          console.log("Negotiation needed...");
          // try {
          //   makingOffer = true;
          //   console.log("making offer...");
          //   await peerConnection.setLocalDescription();
          //   signaler.send({ description: peerConnection.localDescription });
          // } catch (error) {
          //   console.error(error);
          // } finally {
          //   makingOffer = false;
          // }
        };

        peerConnection.oniceconnectionstatechange = () => {
          console.log("oniceconnectionstatechange fired");
          if (peerConnection.iceConnectionState === "failed") {
            console.log("Peer IceConnection state change <<failed>>");
          }
        };

        peerConnection.addEventListener(
          "connectionstatechange",
          async (event) => {
            console.log(
              "Connection state changed to:",
              peerConnection.connectionState
            );
            // if (peerConnection.connectionState === "failed") {
            //   console.log("Reconnecting...");
            //   const { peerConnection, remoteStream } =
            //     await createPeerConnection(addIce);
            //   dispatch(addStream("remote1", remoteStream, peerConnection));

            //   console.log(remoteStream);

            //   largeFeedEl.current.srcObject = remoteStream; // we have the  remotestream from peerconnection, set the video feed to the the remotestream just created
            // }
          }
        );
        //we dont know who we are talking to yet
        dispatch(addStream("remote1", remoteStream, peerConnection));
        //we have a peerconnection and lets make an offer
        //EXCEPT it's not time yet
        //SDP = incofmation about the feed, and we have NO Tracks
        //socket.emit....
        console.log("Remote Stream...");
        console.log(remoteStream);

        largeFeedEl.current.srcObject = remoteStream; // we have the  remotestream from peerconnection, set the video feed to the the remotestream just created
      } catch (error) {
        notify(
          `Unable to access Video or Audio device, close applicatiion(s) which are using Video/Audio.`,
          false
        );
        console.log(error);
      }
    };
    fetchMedia();
  }, []);

  useEffect(() => {
    //we cannot update streamsRef until we know redux is finished
    if (streams.remote1) {
      streamsRef.current = streams;
    }
  }, [streams]);

  useEffect(() => {
    const createOfferAsync = async () => {
      //we have audio, video and we need an offer, let's make it
      for (const s in streams) {
        console.log(s);
        if (s !== "localStream") {
          try {
            const pc = streams[s].peerConnection;
            const offer = await pc.createOffer();
            //#SATHIKUM added await to see does it make any difference in outside network calls
            pc.setLocalDescription(offer);
            // const timer = setTimeout(() => {
            //   console.log("<<< Woke up after 1 second >>>");
            // }, 1000);
            //get the token from the URL for the socket connection
            const token = searchParams.get("token");
            //get the socket from socketConnection
            const socket = socketConnection(token);
            socket.emit("newOffer", { offer, apptInfo });
            //add our event listeners
            // return () => clearTimeout(timer);
          } catch (error) {
            console.log(`createOfferAsync >><< Socket connection exception`);
            console.log(error);
          }
        }
      }
      dispatch(updateCallStatus("haveCreatedOffer", true));
    };
    if (
      callStatus.audio === "enabled" &&
      callStatus.video === "enabled" &&
      !callStatus.haveCreatedOffer
    ) {
      console.log("create offer async");
      createOfferAsync();
    }
  }, [callStatus.audio, callStatus.video, callStatus.haveCreatedOffer]);

  useEffect(() => {
    const asyncAddAnswer = async () => {
      //listen for changes to callStatus  answer
      //if  it exists  we have an answer
      for (const s in streams) {
        if (s !== "localStream") {
          const pc = streams[s].peerConnection;
          await pc.setRemoteDescription(callStatus.answer);
          console.log(pc.signalingState);
          console.log("Answer added...");
        }
      }
    };

    if (callStatus.answer) {
      asyncAddAnswer();
    }
  }, [callStatus.answer]);

  useEffect(() => {
    const token = searchParams.get("token");
    console.log(token);
    const fetchDecodedToken = async () => {
      const resp = await axios.post(
        `${config.chatApiServer}/api/v1/validate-link`,
        {
          token,
        }
      );
      console.log(resp.data);
      setApptInfo(resp.data);
      uuidRef.current = resp.data.fromJid;
    };
    fetchDecodedToken();
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    const socket = socketConnection(token);
    console.log(` socket =  ${socket} token = ${token}`);
    clientSocketListeners(socket, dispatch, addIceCandidateToPc);
  }, []);

  const addIceCandidateToPc = (iceC) => {
    //add an ice candidate  from the remote, to the pc
    for (const s in streamsRef.current) {
      if (s !== "localStream") {
        const pc = streamsRef.current[s].peerConnection;
        switch (pc.signalingState) {
          case "closed":
            console.log("connection is closed");
            break;
          case "stable":
            console.log("connection is stable");
            break;
          default:
            console.log("default...");
        }
        console.log("<<pc>>");
        console.log(pc);
        pc.addIceCandidate(iceC);
        console.log("Added ice candidate to existing page presence");
        setShowCallInfo(false);
      }
    }
  };

  const addIce = (iceC) => {
    //emit ice candidate to the signaling server
    console.log(`--- Add Ice ---`);
    const socket = socketConnection(searchParams.get("token"));
    console.log(socket);
    socket.emit("iceToServer", {
      iceC,
      who: "client",
      uuid: uuidRef.current, //we used useRef to keep the value fresh
    });
  };

  const disConnectCall = () => {
    console.log(`Disconnect Call...`);
    const socket = socketConnection(searchParams.get("token"));
    console.log(apptInfo);
    socket.emit("disConnect", {
      who: "client",
      fromJid: apptInfo.fromJid,
      toJid: apptInfo.toJid,
    });
  };

  const notify = (msg, isSuccess = true) => {
    if (isSuccess) toast.success(msg);
    else toast.error(msg);
  };

  return (
    <div className="main-video-page">
      <div className="video-chat-wrapper">
        {/* Div to hold our remote video, our local video and our chat window */}
        <video
          id="large-feed"
          ref={largeFeedEl}
          autoPlay
          controls
          playsInline
        ></video>
        <video
          id="own-feed"
          ref={smallFeedEl}
          autoPlay
          controls
          playsInline
        ></video>
        {showCallInfo ? <CallInfo apptInfo={apptInfo} /> : <></>}
        {/* <ChatWindow /> */}
      </div>
      <ActionButtons
        smallFeedEl={smallFeedEl}
        largeFeedEl={largeFeedEl}
        redirectHome={false}
        disConnectCall={disConnectCall}
      />
    </div>
  );
};

export default MainVideoPage;

const config = {
  chatApiServer: "https://devapi2.videochat.digicoffer.com",
  chatUiServer: "https://dev2.videochat.digicoffer.com",
  cofferApiServer: "https://apidev2.digicoffer.com",
  xmppServer: "devchat.vitacape.com",
  pchatApiServer: "https://localhost:9000",
  pchatUiServer: "https://localhost:3000",
  pcofferApiServer: "https://api.digicoffer.com",
  pxmppServer: "chat.viitacape.com",
};

export default config;

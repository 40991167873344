import axios from "axios";
import React, { useEffect, useState, useRef } from "react";
import { useSearchParams, useLocation } from "react-router-dom";
import ActionButtons from "./ActionButtons";
import CallInfo from "./Callinfo";
import ChatWindow from "./ChatWindow";
import "./VideoComponents.css";
import addStream from "../redux-elements/actions/addStream";
import { useDispatch, useSelector } from "react-redux";
import createPeerConnection from "../webRTCutilities/createPeerConnection";
import socketConnection from "../webRTCutilities/socketConnection";
import {
  updateCallStatus,
  clearCallStatus,
} from "../redux-elements/actions/updateCallStatus";
import proSocketListeners from "../webRTCutilities/proSocketListeners";
import config from "../config";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "webrtc-adapter";

const ProMainVideoPage = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const callStatus = useSelector((state) => state.callStatus);
  const streams = useSelector((state) => state.streams);
  //get query string finder hook
  const [searchParams, setSeacrchParams] = useSearchParams();
  const [apptInfo, setApptInfo] = useState({});

  //grab the token var out of the querystring
  const smallFeedEl = useRef(null); //this is a React ref to a dom element, so we can interact with it the React way
  const largeFeedEl = useRef(null);
  const [haveGottenIce, setHaveGottenIce] = useState(false);
  const streamsRef = useRef(null);

  // const connectionState = useRef(null);
  // const peerConnectionState = useRef(null);

  useEffect(() => {
    console.log(`=> Fetch Media Called <=`);
    //fetch the user Media
    const fetchMedia = async () => {
      const constraints = {
        video: true, //must have one constraint, just dont show it yet
        audio: true,
      };
      try {
        // console.log(navigator.mediaDevices);
        // navigator.mediaDevices
        //   .enumerateDevices()
        //   .then((devices) => {
        //     console.log(devices);
        //     const videoDevices = devices.filter(
        //       (device) => device.kind === "videoinput"
        //     );
        //     console.log("Available video devices:", videoDevices);
        //   })
        //   .catch((error) => {
        //     console.error("Error enumerating devices:", error);
        //   });
        const stream = await navigator.mediaDevices.getUserMedia(constraints);
        dispatch(updateCallStatus("haveMedia", true)); // update the callstatus reducer that we have a media
        //dispatch will send this function to the redux dispatcher so all the reducers are notified
        //we send 2 args, the who and the stream
        dispatch(addStream("localStream", stream));
        const { peerConnection, remoteStream } = await createPeerConnection(
          addIce
        );
        // peerConnectionState.current = peerConnection;
        //we dont know who we are talking to yet
        dispatch(addStream("remote1", remoteStream, peerConnection));
        //we have a peerconnection and lets make an offer
        //EXCEPT it's not time yet
        //SDP = incofmation about the feed, and we have NO Tracks
        //socket.emit....

        //the below code is causing the issue ???, commented oout
        //const tracks = remoteStream.getTracks();

        //#SATHNEW perfect negotiation pattern
        peerConnection.onnegotiationneeded = async () => {
          console.log("Negotiation needed...");
        };

        peerConnection.oniceconnectionstatechange = () => {
          console.log("oniceconnectionstatechange fired");
          if (peerConnection.iceConnectionState === "failed") {
            console.log("Peer IceConnection state change <<failed>>");
          }
        };

        console.log(remoteStream);
        //handling unable to do video chat across networks
        // peerConnection.current.onconnectionstatechange =
        //   handleConnectionStateChange;
        largeFeedEl.current.srcObject = remoteStream; // we have the  remotestream from peerconnection, set the video feed to the the remotestream just created
      } catch (error) {
        notify(
          `Unable to access Video or Audio device, close applicatiion(s) which are using Video/Audio.`,
          false
        );
        console.log("Error ====><=====");
        console.log(error);
      }
    };
    fetchMedia();
  }, []);
  //  }, [location, largeFeedEl]);

  // const handleConnectionStateChange = () => {
  //   console.log("Connection state changed...");
  //   connectionState.current = peerConnectionState.current.connectionState;
  //   console.log("Connection state:", connectionState.current);
  //   if (connectionState.current === "failed") {
  //     // Handle connection failure
  //     console.error("Connection failed, dont have reconnecting logic");
  //     // Implement your error handling logic here, such as attempting to reconnect or displaying an error message to the user
  //   }
  // };

  useEffect(() => {
    //we cannot update streamsRef until we know redux is finished
    if (streams.remote1) {
      streamsRef.current = streams;
    }
  }, [streams]);

  useEffect(() => {
    const getIceAsync = async () => {
      // console.log(`<= Inside getIceAsync =>`);
      const socket = socketConnection(searchParams.get("token"));
      const uuid = searchParams.get("uuid");
      const iceCandidates = await socket.emitWithAck(
        "getIce",
        uuid,
        "professional"
      );
      console.log("iceCandidate Received");
      console.log(iceCandidates);
      iceCandidates.forEach((iceC) => {
        for (const s in streams) {
          if (s !== "localStream") {
            const pc = streams[s].peerConnection;
            switch (pc.signalingState) {
              case "closed":
                console.log("connection is closed");
                break;
              case "stable":
                console.log("connection is stable");
                break;
              default:
                console.log("default...");
            }
            pc.addIceCandidate(iceC);
            console.log("==== Added ice Candidate ====");
          }
        }
      });
    };
    if (streams.remote1 && !haveGottenIce) {
      setHaveGottenIce(true);
      getIceAsync();
      streamsRef.current = streams; //update streamsRef once we know streams exists
    }
  }, [streams, haveGottenIce]);

  useEffect(() => {
    const setAsyncOffer = async () => {
      for (const s in streams) {
        if (s !== "localStream") {
          const pc = streams[s].peerConnection;
          await pc.setRemoteDescription(callStatus.offer);
          console.log(pc.signalingState); //should be have remote offer
        }
      }
    };
    if (callStatus.offer && streams.remote1 && streams.remote1.peerConnection) {
      setAsyncOffer();
    }
  }, [callStatus.offer, streams.remote1]);

  useEffect(() => {
    const createAnswerAsync = async () => {
      //we  have a video and audio, we can make an answer  and localDescription
      for (const s in streams) {
        if (s !== "localStream") {
          const pc = streams[s].peerConnection;
          //make an answer
          const answer = await pc.createAnswer();
          //because this is the answering client, the answer is the localDesc
          await pc.setLocalDescription(answer);
          console.log(pc.signalingState); // should have local  answer
          // const timer = setTimeout(() => {
          //   console.log("<<< Woke up after 1 second >>>");
          // }, 1000);
          dispatch(updateCallStatus("haveCreatedAnswer", true));
          dispatch(updateCallStatus("answer", answer));
          //emit the answer to the server
          const token = searchParams.get("token");
          const socket = socketConnection(token);
          const uuid = searchParams.get("uuid");
          console.log("emitting", answer, uuid);
          socket.emit("newAnswer", { answer, uuid });
          // return () => clearTimeout(timer);
        }
      }
    };

    //we only create an answer if anudio and video is enabled AND havecreated answer  is false
    //this may run many times, but these three events happens only one time
    if (
      callStatus.audio === "enabled" &&
      callStatus.video === "enabled" &&
      !callStatus.haveCreatedAnswer
    ) {
      createAnswerAsync();
    }
  }, [callStatus.audio, callStatus.video, callStatus.haveCreatedAnswer]);

  useEffect(() => {
    const token = searchParams.get("token");
    console.log(token);
    const fetchDecodedToken = async () => {
      const resp = await axios.post(
        `${config.chatApiServer}/api/v1/validate-link`,
        {
          token,
        }
      );
      // console.log(resp.data);
      setApptInfo(resp.data);
    };
    fetchDecodedToken();
  }, []);

  useEffect(() => {
    const token = searchParams.get("token");
    const socket = socketConnection(token);
    proSocketListeners.proVideoSocketListeners(socket, addIceCandidateToPc);
  }, []);

  const addIceCandidateToPc = (iceC) => {
    //add an ice candidate  from the remote, to the pc
    for (const s in streamsRef.current) {
      if (s !== "localStream") {
        const pc = streamsRef.current[s].peerConnection;
        switch (pc.signalingState) {
          case "closed":
            console.log("connection is closed");
            break;
          case "stable":
            console.log("connection is stable");
            break;
          default:
            console.log("default...");
        }
        console.log("<<pc>>");
        console.log(pc);
        pc.addIceCandidate(iceC);
        console.log("Added ice candidate to existing page presence");
      }
    }
  };

  const disConnectCall = () => {
    // console.log(`Disconnect Call...`);
    const socket = socketConnection(searchParams.get("token"));
    console.log(apptInfo);
    socket.emit("disConnect", {
      who: "professional",
      fromJid: apptInfo.fromJid,
      toJid: apptInfo.toJid,
    });
  };

  const addIce = (iceC) => {
    //emit ice candidate to the signaling server
    console.log(apptInfo);
    const socket = socketConnection(searchParams.get("token"));
    socket.emit("iceToServer", {
      iceC,
      who: "professional",
      uuid: searchParams.get("uuid"), //we used useRef to keep the value fresh
    });
  };

  const notify = (msg, isSuccess = true) => {
    if (isSuccess) toast.success(msg);
    else toast.error(msg);
  };

  return (
    <div className="main-video-page">
      <div className="video-chat-wrapper">
        {/* Div to hold our remote video, our local video and our chat window */}
        <video
          id="large-feed"
          ref={largeFeedEl}
          autoPlay
          controls
          playsInline
        ></video>
        <video
          id="own-feed"
          ref={smallFeedEl}
          autoPlay
          controls
          playsInline
        ></video>
        {callStatus.video === "off" || callStatus.audio === "off" ? (
          <div className="call-info">
            <h2>
              Connected with client.
              <br />
              The call will start when video and audio is enabled.
            </h2>
          </div>
        ) : (
          <></>
        )}
        <ChatWindow />
      </div>
      <ActionButtons
        smallFeedEl={smallFeedEl}
        largeFeedEl={largeFeedEl}
        redirectHome={true}
        disConnectCall={disConnectCall}
      />
    </div>
  );
};

export default ProMainVideoPage;

import { io } from "socket.io-client";
import config from "../config";

let socket;
const socketConnection = (jwt) => {
  //check to see if socket is already connected
  if (socket && socket.connected) {
    //if so return it so whoever needs it, can use it
    console.log(` < Socket Connect Exists >`);
    console.log(socket);
    return socket;
  } else {
    //it's not connected, connect
    console.log(
      ` < Socket Connect does not Exist, getting to know the socket >`
    );
    socket = io.connect(config.chatApiServer, {
      auth: {
        jwt,
      },
    });
    console.log(` < Socket > -> `);
    console.log(socket);
    return socket;
  }
};

export default socketConnection;

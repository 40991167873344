import { useEffect, useState, useRef } from "react";
import "./ProDashboard.css";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import socketConnection from "../webRTCutilities/socketConnection";
import proSocketListeners from "../webRTCutilities/proSocketListeners";
import moment from "moment";
import { useDispatch } from "react-redux";
import config from "../config";
import { Strophe, $msg, $pres, $iq } from "strophe.js";
import { useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css
import CustomButton from "./customButton/customButton";

const ProDashboard = () => {
  //the below code is for testing purpose only, sometimes miracles do happen
  const location = useLocation();
  const [count, setCount] = useState(0);

  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const [apptInfo, setApptInfo] = useState([]);
  const [clients, setClients] = useState([]);
  const [newClients, setNewClients] = useState([]);
  const [clientUsers, setClientUsers] = useState([]);
  const [team, setTeam] = useState([]);

  const [newTeam, setNewTeam] = useState([]);
  const [newClientUsers, setNewClientUsers] = useState([]);

  const [prevId, setPrevId] = useState("");
  const [prevClientId, setPrevClientId] = useState("");

  const dispatch = useDispatch();
  const authToken = searchParams.get("authtoken");
  const [decodedToken, setDecodedToken] = useState({});
  const [doRefresh, setDoRefresh] = useState(true);
  const [connection, setConnection] = useState(null);
  const loginState = useSelector((state) => state.loginState);
  const [myPresence, setMyPresence] = useState({
    show: "chat", // Default presence status, can be 'chat', 'away', etc.
    status: "Available", // Default status message
  });
  const [receivedPresenceUpdate, setReceivedPresenceUpdate] = useState(false);

  useEffect(() => {
    const waitingCall = apptInfo.find((call) => call.waiting === true);
    if (waitingCall) {
      //notify(`Incoming Call...`);
      const clientName = getClientName(waitingCall.fromJid);

      // confirmAlert({
      //   customUI: ({ onClose }) => (
      //     <div className="custom-ui">
      //       <h2>Video Call</h2>
      //       <p>Accept Call from {clientName}</p>
      //       <CustomButton
      //         variant="contained"
      //         color="primary"
      //         onClick={() => {
      //           joinCall(waitingCall);
      //           onClose();
      //         }}
      //       >
      //         Yes
      //       </CustomButton>
      //       <CustomButton
      //         variant="contained"
      //         color="secondary"
      //         onClick={() => {
      //           onClose();
      //         }}
      //       >
      //         No
      //       </CustomButton>
      //     </div>
      //   ),
      // });

      confirmAlert({
        title: "Video Call",
        message: `Accept Call from ${clientName} ?`,
        buttons: [
          {
            label: "Yes",
            onClick: () => joinCall(waitingCall),
          },
          {
            label: "No",
            onClick: () => null,
          },
        ],
      });
    }
  }, [apptInfo]);

  useEffect(() => {
    const fetchClient = async () => {
      await getClients();
    };

    fetchClient();

    getTeam();
  }, []);

  // useEffect(() => {
  //   setCount(count + 1);
  //   console.log(`${count}-Location changed!`, location.pathname);

  //   sendPresence();
  // }, [location]);

  useEffect(() => {
    // console.log(`Do Refresh -> ${doRefresh}`);
    if (doRefresh) {
      setDoRefresh(false);
      const token = searchParams.get("token");
      console.log(` token => ${token}`);
      const socket = socketConnection(token);
      console.log(` soocket => ${socket}`);
      proSocketListeners.proDashboardSocketListeners(
        socket,
        setApptInfo,
        dispatch
      );
      console.log(`**** apptInfo ***`);
      console.log(apptInfo);

      //console.log(token);
      //let decodedToken = {};
      const fetchDecodedToken = async () => {
        const resp = await axios.post(
          `${config.chatApiServer}/api/v1/validate-link`,
          {
            token,
          }
        );
        //decodedToken = resp.data;
        // console.log(resp.data);
        setDecodedToken(resp.data);
        getChatQueue(decodedToken.toJid);

        // setApptInfo([resp.data]);
      };
      fetchDecodedToken();
    }
  }, [doRefresh]);

  useEffect(() => {
    // Initialize XMPP connection
    // console.log(loginState);
    const xmppConnection = new Strophe.Connection(
      `wss://${config.xmppServer}:5443/ws`,
      { protocol: "wss" }
    );
    // console.log(xmppConnection);
    const myJid =
      loginState.authData.data.uid + "_" + loginState.authData.data.user_id;
    console.log(`connecting as ${myJid}@${config.xmppServer}`);
    xmppConnection.connect(
      `${myJid}@${config.xmppServer}`,
      loginState.authData.token,
      onConnect
    );
    setConnection(xmppConnection);

    function onConnect(status) {
      // console.log(`current status = ${status}`);
      // console.log(`open = ${Strophe.Status.CONNECTED}`);
      if (status === Strophe.Status.CONNECTED) {
        console.log("connected...");
        setupMessageListener();
        //for loop here to each of the clients
        //#SATHIKUM need to call api /notify for each relationship and then subscribe
        //https://apidev2.digicoffer.com/professional/relationship/65b9e6c9a1db720418e50c8a/users/notify
        clients.forEach((cl) => {
          subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        });
        //Team Member code here
        team.forEach((tm) => {
          tm.users.forEach((user) => {
            subscribeToPresence(`${user.guid}@${config.xmppServer}`);
          });
        });

        newClientUsers.forEach((cl) => {
          subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
        });
      } else if (status === Strophe.Status.DISCONNECTED) {
        console.log("Disconnected from XMPP server");
      }
    }

    const setupMessageListener = () => {
      const presenceStanza = $pres();
      presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
      presenceStanza.c("status").t("Available for a chat");

      xmppConnection.deleteHandler(onPresence);
      xmppConnection.addHandler(onPresence, null, "presence");
      //xmppConnection.send($pres()); // Send presence to indicate online status
      xmppConnection.send(presenceStanza);
    };

    const sendPresence = () => {
      const presenceStanza = $pres();
      presenceStanza.c("show").t("chat"); // 'chat' represents 'available' status
      presenceStanza.c("status").t("Available for a chat");
      xmppConnection.deleteHandler(onPresence);
      xmppConnection.addHandler(onPresence, null, "presence");
      xmppConnection.send(presenceStanza);
      // console.log("presence sent --->");
    };

    const onPresence = (presence) => {
      // console.log(`Received prssence => ${presence}`);
      // console.log(presence);
    };

    const subscribeToPresence = (jid) => {
      xmppConnection.deleteHandler(onPresenceUpdate);

      xmppConnection.addHandler(
        onPresenceUpdate,
        null,
        "presence",
        null,
        null,
        jid,
        { matchBare: true }
      );
      xmppConnection.send($pres({ to: jid }));
    };

    const onPresenceUpdate = (presenceStanza) => {
      // Handle the incoming presence update
      const fromJID = Strophe.getBareJidFromJid(
        presenceStanza.getAttribute("from")
      );
      // console.log(fromJID.replace(/@.*/gm, ""));
      changeOnlineStatus(fromJID.replace(/@.*/gm, ""), true);
      // console.log(newClients);
      const show = presenceStanza.querySelector("show");
      const status = presenceStanza.querySelector("status");
      // Update your own presence based on the received presence
      const updatedPresence = {
        show: show ? show.textContent : "chat", // Default to 'chat' if not available
        status: status ? status.textContent : "Available", // Default to 'Available' if not available
      };
      setMyPresence(updatedPresence);
      setReceivedPresenceUpdate(true);

      //sendPresence();
      // You can now use the presence information in your React component state or perform any other actions.
    };

    const myPresenceInterval = setInterval(() => {
      sendPresence();
    }, 3000); // 3000 milliseconds = 3 seconds

    const presenceInterval = setInterval(() => {
      //#SATHIKUM needs a relook because of the //notify API
      clients.forEach((cl) => {
        subscribeToPresence(`${cl.guid}@${config.xmppServer}`);
      });

      team.forEach((tm) => {
        tm.users.forEach((user) => {
          subscribeToPresence(`${user.guid}@${config.xmppServer}`);
        });
      });

      // console.log(newClients);

      newClients.forEach((client) => {
        client.users.forEach((user) => {
          // console.log(`Subscribing <>-<> ${user.guid}@${config.xmppServer}`);
          subscribeToPresence(`${user.guid}@${config.xmppServer}`);
        });
      });
    }, 3000); // 3000 milliseconds = 3 seconds

    // Clean up the interval when the component is unmounted

    return () => {
      // Disconnect when component unmounts
      console.log("disconnecting...");
      clearInterval(presenceInterval);
      clearInterval(myPresenceInterval);
      xmppConnection.disconnect();
    };
  }, [
    decodedToken,
    clients,
    team,
    newClientUsers,
    receivedPresenceUpdate,
    location,
  ]);

  // useEffect(() => {
  //   getChatQueue(decodedToken.toJid);
  // }, [decodedToken]);

  const getClientName = (jid) => {
    let clientName = "";
    const fromJid = jid.replace(/@.*/gm, "");
    const client = clients.find((client) => client.guid === fromJid);
    if (client) {
      return client.adminName;
    } else {
      team.forEach((tm) => {
        const client = tm.users.find((user) => user.guid === fromJid);
        if (client) {
          clientName = client.name;
          return client.name;
        }
      });
    }

    newClients.forEach((client) => {
      if (client.users) {
        const cl = client.users.find((user) => user.guid === fromJid);
        console.log(cl);
        if (cl) {
          clientName = cl.name;
          return cl.name;
        }
      }
    });
    return clientName;
  };

  const changeOnlineStatus = (jid, newStatus) => {
    // console.log(` Received presence from <<${jid}>>`);
    let nClients = newClients;
    let nTeam = newTeam;
    let cUsers = newClientUsers;
    nClients.forEach((client) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      client.online = client.guid === jid ? true : client.online;
    });
    // setNewClients((prevStates) =>
    //   prevStates.map((client) =>
    //     client.guid === jid ? { ...client, online: newStatus } : client
    //   )
    // );
    nTeam.forEach((group) => {
      group.users.forEach((user) => {
        user.online = user.guid === jid ? true : user.online;
      });
    });

    cUsers.forEach((user) => {
      //Unsure when to send client offline, in this case. Otherwise it is toggling
      // client.online = client.guid === jid ? true : false;
      const isOnline = user.online ? user.online : false;
      user.online = user.guid === jid ? true : isOnline;
    });
    // console.log(cUsers);
    setClients(nClients);
    setNewClients(nClients);
    setNewTeam(nTeam);
    setNewClientUsers(cUsers);
  };

  const getChatQueue = async (toJid) => {
    console.log("-> Inside getChatQueue ->");
    // const bearer_token = sessionStorage.getItem("latoken");
    //const bearer_token =
    // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI5RjNCOTNDMTE3NkNFRTlBIiwiYWRtaW4iOmZhbHNlLCJwbGFuIjoibGF1ZGl0b3IiLCJyb2xlIjoiU1UiLCJuYW1lIjoiU2F0aGlzaCIsInVzZXJfaWQiOiI2M2ZkOGE3YTM4NzQ1OWQ1YmZjOWQ1NDQiLCJleHAiOjE3MDUzMzY5NTJ9.iON4p5_bgW16Z1ozT_-NYWDweo9IJOU7G1yV7j-3HUc";
    const chatAPIUrl = `${config.chatApiServer}/api/v1/chatuser-queue?tojid=${toJid}`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.get(chatAPIUrl, config);
      // console.log(response.data);
      const chatInfo = await response.data;
      setApptInfo([chatInfo]);
      //setApptInfo(clients)
    } catch (error) {
      console.log(error);
    }
  };

  const getTeam = async () => {
    //professional/chatusers
    const prodUrl = `${config.cofferApiServer}/professional/chatusers`;
    try {
      const config = {
        headers: {
          Authorization: `Bearer ${authToken}`,
        },
      };
      const response = await axios.get(prodUrl, config);
      const chatTeam = await response.data.groups;
      setTeam(chatTeam);

      const nTeam = chatTeam.map((group) => ({
        ...group,
        users: group.users.map((user) => ({ ...user, online: false })),
      }));

      // console.log(`<<< Chat Team >>>`);
      // console.log(nTeam);

      setNewTeam(nTeam);
      //setApptInfo(clients)
    } catch (error) {
      console.log(error);
    }
  };

  const addOnlineStatus = async (clients, restUrl) => {
    return new Promise(async (resolve) => {
      let clientData = clients;
      const totalRows = clients.length;
      let row = 1;
      clientData.forEach((client) => {
        row++;
        //restUrl = `${config.cofferApiServer}/professional/relationship/${client.id}/users/notify`;
        (async () => {
          if (client.clientType !== "consumer") {
            let users = await getClientUsers(restUrl, client.id);
            users.forEach((obj) => {
              obj["online"] = false;
            });
            client["users"] = users;
            client["online"] = false;
            client["isExpanded"] = false;
            console.log(`Assined users `);
          } else {
            client["users"] = [];
            client["online"] = false;
            client["isExpanded"] = false;
          }
        })();
      });
      if (row >= totalRows) {
        setClients(clientData);
        setNewClients(clientData);
        // console.log(`new Clients >>`);
        // console.log(newClients);
      }
      resolve(clientData);
    });
  };

  const getClients = async () => {
    return new Promise(async (resolve) => {
      // const bearer_token = sessionStorage.getItem("latoken");
      //const bearer_token =
      // "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ1aWQiOiI5RjNCOTNDMTE3NkNFRTlBIiwiYWRtaW4iOmZhbHNlLCJwbGFuIjoibGF1ZGl0b3IiLCJyb2xlIjoiU1UiLCJuYW1lIjoiU2F0aGlzaCIsInVzZXJfaWQiOiI2M2ZkOGE3YTM4NzQ1OWQ1YmZjOWQ1NDQiLCJleHAiOjE3MDUzMzY5NTJ9.iON4p5_bgW16Z1ozT_-NYWDweo9IJOU7G1yV7j-3HUc";
      const prodUrl = `${config.cofferApiServer}/professional/v2/relationship`;
      const restUrl = `${config.cofferApiServer}/professional/relationship`;
      let clientData = [];
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const response = await axios.get(prodUrl, config);
        clientData = await response.data.data.relationships;
        clientData = await addOnlineStatus(clientData, restUrl);

        setClientUsers(clientData);
        // console.log(` <> Clients <>`);
        // console.log(clientData);
        // console.log(clientUsers);

        setClients(clientData);
      } catch (error) {
        console.log(error);
      }
    });
  };

  const getClientUsers = async (restUrl, clientId) => {
    return new Promise(async (resolve) => {
      try {
        const config = {
          headers: {
            Authorization: `Bearer ${authToken}`,
          },
        };
        const apiUrl = `${restUrl}/${clientId}/users/notify`;
        const response = await axios.get(apiUrl, config);
        resolve(response.data.data.users);
      } catch (error) {
        console.error(error);
        resolve([]);
      }
    });
  };

  //function to make a call to contact
  /*
    callInfo = { 
      fromJid,
      toJid,
      fullName
    }

  */
  const makeCall = (callInfo) => {
    // console.log("Inside MakeCall");
    console.log(callInfo);
    // console.log(authToken);
    try {
      let data = JSON.stringify({
        token: authToken,
        fromJid: callInfo.fromJid.replace(/@.*/gm, ""),
        fullName: callInfo.fullName,
        toJid: callInfo.toJid.replace(/@.*/gm, ""),
      });

      let restConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${config.chatApiServer}/api/v1/chatuser-link`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };

      axios
        .request(restConfig)
        .then((response) => {
          // navigate(response.data); //tried and tested but not working as expected, need to relook finally
          window.open(`${config.chatUiServer}${response.data}`, "_blank");
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {}
  };

  const joinCall = (appt) => {
    // console.log("Inside JoinCall =>");
    console.log(appt);
    try {
      let data = JSON.stringify({
        token: authToken,
        fromJid: appt.fromJid.replace(/@.*/gm, ""),
        fullName: appt.clientName,
        toJid: appt.toJid.replace(/@.*/gm, ""),
      });
      // console.log("----- data -----");
      // console.log(data);

      let restConfig = {
        method: "post",
        maxBodyLength: Infinity,
        url: `${config.chatApiServer}/api/v1/clientpro-link2`,
        headers: {
          "Content-Type": "application/json",
        },
        data: data,
      };
      axios
        .request(restConfig)
        .then((response) => {
          //navigate(response.data);
          if (response.data.token) {
            // const socket = socketConnection(response.data.token);
            // proSocketListeners.proDashboardSocketListeners(
            //   socket,
            //   setApptInfo,
            //   dispatch
            // );

            const url = `/join-video-pro?token=${response.data.token}&uuid=${appt.uuid}&client=${appt.clientName}`;
            // const newTab = window.open(url, "chatWindow");
            navigate(url);
            // newTab.location.href = url;
          }
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (error) {
      console.log(error);
    }

    // const token = searchParams.get("token");
    // //navigate to /join-video-pro
    // navigate(
    //   `/join-video-pro?token=${token}&uuid=${appt.uuid}&client=${appt.clientName}`
    // );
  };

  const toggleClientExpand = async (itemId) => {
    //following code is less process intensive, but for some issue so need to check
    if (prevClientId !== itemId) {
      const cl = newClients.find((client) => client.id === itemId);
      // console.log(cl);
      if (cl) setNewClientUsers(cl.users);
      else setNewClientUsers([]);
    }

    setNewClients((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
    //collapse the previous expanded object
    if (prevClientId !== "" && prevClientId !== itemId) {
      setNewClients((prevItems) =>
        prevItems.map((item) =>
          item.id === prevClientId ? { ...item, isExpanded: false } : item
        )
      );
    }
    setPrevClientId(itemId);
  };

  const toggleExpand = (itemId) => {
    //Expand the current object
    setNewTeam((prevItems) =>
      prevItems.map((item) =>
        item.id === itemId ? { ...item, isExpanded: !item.isExpanded } : item
      )
    );
    //collapse the previous expanded object
    if (prevId !== "" && prevId !== itemId) {
      setNewTeam((prevItems) =>
        prevItems.map((item) =>
          item.id === prevId ? { ...item, isExpanded: false } : item
        )
      );
    }
    setPrevId(itemId);
  };
  const notify = (msg) => toast.success(msg);
  return (
    <div className="container">
      <ToastContainer />
      <div className="chat-container">
        <div className="region-section">
          <div className="region-title">Clients</div>
          <div className="client-cards-container">
            {newClients.map((client) => (
              <>
                {client.clientType.toLowerCase() === "consumer" ? (
                  <div className="client-card">
                    {/* <div className="client-name">{client.name}</div> */}
                    <div className="client-email">{client.adminName}</div>
                    <div className="action-buttons">
                      <button
                        className="action-button"
                        onClick={() =>
                          makeCall({
                            fullName: client.adminName,
                            fromJid: decodedToken.toJid,
                            toJid: client.guid,
                          })
                        }
                        style={{
                          display:
                            client.online && client.users.length === 0
                              ? "inline-block"
                              : "none",
                        }}
                      >
                        Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                      </button>
                      {client.online ? <></> : <>offline</>}
                      {apptInfo.map((a) => (
                        <>
                          {a.waiting &&
                          a.fromJid ===
                            client.guid + `@${config.xmppServer}` ? (
                            <button
                              key={a.uuid}
                              className="action-button"
                              onClick={() => joinCall(a)}
                            >
                              <i className="fas fa-check"></i> Accept
                            </button>
                          ) : (
                            <></>
                          )}
                        </>
                      ))}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="group-card">
                      <div className="client-name">{client.name}</div>
                      <div className="group-button">
                        <div
                          className="expand-button"
                          onClick={() => toggleClientExpand(client.id)}
                        >
                          {client.isExpanded ? "-" : "+"}
                        </div>
                      </div>
                    </div>
                    {client.isExpanded && (
                      <div className="sub-items">
                        {newClientUsers.map((member, index) => (
                          <div key={index} className="client-card">
                            <div className="client-email">{member.name}</div>
                            <div className="action-buttons">
                              <button
                                className="action-button"
                                onClick={() =>
                                  makeCall({
                                    fullName: member.name,
                                    fromJid: decodedToken.toJid,
                                    toJid: member.guid,
                                  })
                                }
                                style={{
                                  display: member.online
                                    ? "inline-block"
                                    : "none",
                                }}
                              >
                                Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                              </button>
                              {member.online ? <></> : <>offline</>}

                              {apptInfo.map((a) => (
                                <>
                                  {a.waiting &&
                                  a.fromJid ===
                                    member.guid + `@${config.xmppServer}` ? (
                                    <button
                                      key={a.uuid}
                                      className="action-button"
                                      onClick={() => joinCall(a)}
                                    >
                                      <i className="fas fa-check"></i> Accept
                                    </button>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              ))}
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            ))}

            {/* <!-- Add more North America clients as needed --> */}
          </div>
        </div>

        <div className="region-section">
          <div className="region-title">Team</div>
          <div className="client-cards-container">
            {newTeam.map((team) => (
              <>
                <div className="group-card">
                  <div className="client-name">{team.groupName}</div>
                  <div className="group-button">
                    <div
                      className="expand-button"
                      onClick={() => toggleExpand(team.id)}
                    >
                      {team.isExpanded ? "-" : "+"}
                    </div>
                  </div>
                </div>
                <>
                  {team.isExpanded && (
                    <div className="sub-items">
                      {team.users.map((member, index) => (
                        <div key={index} className="client-card">
                          <div className="client-email">{member.name}</div>
                          <div className="action-buttons">
                            <button
                              className="action-button"
                              onClick={() =>
                                makeCall({
                                  fullName: member.name,
                                  fromJid: decodedToken.toJid,
                                  toJid: member.guid,
                                })
                              }
                              style={{
                                display: member.online
                                  ? "inline-block"
                                  : "none",
                              }}
                            >
                              Call&nbsp;&nbsp;<i className="fas fa-phone"></i>
                            </button>
                            {member.online ? <></> : <>offline</>}
                            {apptInfo.map((a) => (
                              <>
                                {a.waiting &&
                                a.fromJid ===
                                  member.guid + `@${config.xmppServer}` ? (
                                  <button
                                    key={a.uuid}
                                    className="action-button"
                                    onClick={() => joinCall(a)}
                                  >
                                    <i className="fas fa-check"></i> Accept
                                  </button>
                                ) : (
                                  <></>
                                )}
                              </>
                            ))}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </>
              </>
            ))}

            {/* <!-- Add more Team Members as needed --> */}
          </div>
        </div>

        {/* <!-- Add more region sections as needed --> */}
      </div>
    </div>
  );
};

export default ProDashboard;

import moment from "moment";
import { useEffect, useState } from "react";

const CallInfo = ({ apptInfo }) => {
  console.log(apptInfo);
  // const [momentText, setMomentText] = useState(
  //   moment(apptInfo.apptDate).fromNow()
  // );

  // useEffect(() => {
  //   const timeInterval = setInterval(() => {
  //     // setMomentText(moment(apptInfo.apptDate).fromNow());
  //     console.log("Delaying...5 sec");
  //   }, 5000);
  //   //clean up function
  //   return () => {
  //     // console.log("Clearing")
  //     clearInterval(timeInterval);
  //   };
  // }, []);

  return (
    <div className="call-info">
      <h2>
        Waiting for {apptInfo.clientName} to accept...
        {/* {apptInfo.professionalFullName} has been notified.
        <br />
        Your appointment is {momentText}. */}
      </h2>
    </div>
  );
};

export default CallInfo;
